import React from "react";
import Layout from "./src/components/common/Layout";
import "./src/style/reset.css";
import { ThemeProvider } from "styled-components";
import theme from "./src/theme";

export const wrapPageElement = ({ element, props }) => {
  return (
    <ThemeProvider theme={theme}>
      <Layout {...props}>{element}</Layout>
    </ThemeProvider>
  );
};
