import React from "react";
import styled from "styled-components";
import NavBar from "./NavBar";
import MobileNav from "./MobileNav";
import { Link } from "gatsby";

const Header = () => {
  return (
    <Link to="/">
      <HeaderContainer>
        <Title>New York Music & Art Academy</Title>
        <NavBar />
      </HeaderContainer>
    </Link>
  );
};

const HeaderContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  /* height: 160px; */
  background-color: white;

  position: fixed;
  z-index: 999;
`;
const Title = styled.span`
  text-align: center;

  font-family: "Italiana", sans-serif;
  font-weight: 500;
  font-style: normal;
  font-size: 35px;
  margin: 20px 0px;

  @media ${({ theme }) => theme.device.mobile} {
    font-size: 24px;
  }
`;

export default Header;
