import { Link } from "gatsby";
import React from "react";
import styled from "styled-components";
import { useLocation } from "@reach/router";

type NavProps = {
  selected: boolean;
};
const NavBar = () => {
  const { pathname } = useLocation();
  return (
    <NavBarContainer>
      <Link to="/">
        <Nav selected={pathname === "/"}>HOME</Nav>
      </Link>
      <Link to="/about">
        <Nav selected={pathname === "/about/"}>ABOUT US</Nav>
      </Link>
      <Link to="/lesson">
        <Nav selected={pathname === "/lesson/"}>LESSONS</Nav>
      </Link>
      <Link to="/gallery">
        <Nav selected={pathname === "/gallery/"}>GALLERY</Nav>
      </Link>
      <Link to="/contact">
        <Nav selected={pathname === "/contact/"}>CONTACT</Nav>
      </Link>
    </NavBarContainer>
  );
};

const NavBarContainer = styled.div`
  display: flex;
  justify-content: center;
  gap: 10px;
  padding: 0px 0px 20px;

  a {
    text-decoration: none;
    color: black;
  }
  @media ${({ theme }) => theme.device.mobile} {
    display: none;
  }
`;
const Nav = styled.div<NavProps>`
  display: flex;
  justify-content: center;
  align-items: center;

  width: 160px;
  height: 40px;
  border-radius: 100px;

  font-family: "Julius Sans One", sans-serif;
  font-weight: 400;
  font-style: normal;
  font-size: 15px;

  background-color: ${(props) => props.selected && "#eaeaea"};

  &:hover {
    background-color: #eaeaea;
    transition: background-color 0.6s ease;
  }
`;
export default NavBar;
