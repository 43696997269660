import React from "react";
import Header from "./Header";
import { PageProps } from "gatsby";
import styled from "styled-components";
import MobileNav from "./MobileNav";

const Layout: React.FC<PageProps> = ({ children }: any) => {
  return (
    <Wrapper>
      <Header />
      <MobileNav />

      <Main>{children}</Main>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  height: 100vh;
`;
const Main = styled.div`
  padding-top: 160px;
  display: flex;
  flex-direction: column;
  /* height: calc(100vh - 160px); */
  @media ${({ theme }) => theme.device.mobile} {
    padding-top: 80px;
  }
`;
export default Layout;
