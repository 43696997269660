import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { IoMenu } from "react-icons/io5";
import { Link } from "gatsby";
import { useLocation } from "@reach/router";

const MobileNav = () => {
  const [isNavOpen, setIsNavOpen] = useState(false);
  const location = useLocation();

  const handleNavClick = () => setIsNavOpen(false);
  useEffect(() => {
    setIsNavOpen(false);
  }, [location.pathname]);
  return (
    <>
      <IconContainer onClick={() => setIsNavOpen(!isNavOpen)}>
        <IoMenu size={28} />
      </IconContainer>
      {isNavOpen && (
        <NavWrapper>
          <LinkContainer>
            <Link to="/">
              <Nav onClick={handleNavClick}>HOME</Nav>
            </Link>
            <Link to="/about">
              <Nav onClick={handleNavClick}>ABOUT US</Nav>
            </Link>
            <Link to="/lesson">
              <Nav onClick={handleNavClick}>LESSONS</Nav>
            </Link>
            <Link to="/gallery">
              <Nav onClick={handleNavClick}>GALLERY</Nav>
            </Link>
            <Link to="/contact">
              <Nav onClick={handleNavClick}>CONTACT</Nav>
            </Link>
          </LinkContainer>
        </NavWrapper>
      )}
    </>
  );
};

const Nav = styled.span`
  font-size: 20px;
  font-family: "Julius Sans One", sans-serif;
`;
const LinkContainer = styled.div`
  position: absolute;
  right: 34px;
  bottom: 90px;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  gap: 10px;
`;
const NavWrapper = styled.div`
  width: 100%;
  height: 100%;
  position: absolute;
  background-color: white;
  z-index: 998;
  position: fixed;
  display: none;
  @media ${({ theme }) => theme.device.mobile} {
    display: block;
  }
`;
const IconContainer = styled.div`
  position: fixed;

  width: 50px;
  height: 50px;
  bottom: 20px;
  right: 20px;
  z-index: 999;
  background-color: white;
  border-radius: 100px;

  display: none;

  @media ${({ theme }) => theme.device.mobile} {
    display: block;
    display: flex;
    align-items: center;
    justify-content: center;
  }
`;
export default MobileNav;
