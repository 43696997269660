const deviceSizes = {
  mobile: "375px",
  tablet: "768px",
  laptop: "1024px",
};
const device = {
  mobile: "screen and (max-width:455px)",
  tablet: "screen and (min-width:768px) and (max-width:1023px)",
  laptop: "screen and (min-width:1024px)",
  mobileAndTablet: "screen and (max-width:1023px)",
};
const theme = {
  device,
};
export default theme;
